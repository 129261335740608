import React from "react";
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  rectSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import useImagesSection from "./useImagesSection";
import { videoTag } from "..";
import VimeoFormModal from "../components/VimeoFormModal";
import { Lot, LotsImage } from "../../../../generated/types";
import { useToast } from "../../../../providers/notification.provider";
import useLot from "../useLot";
import BulkUploadModal from "../components/BulkUploadModal";
import InlineEdit from "../../../InlineEdit";
import UploadThumbnailModal from "../components/UploadThumbnailModal";

interface ImageSectionProps {
  lot: Lot;
  setVimeoDeleteAction: (video: Array<any>) => void;
  setVimeoVideo: (video: any) => void;
}

const ImageSection: React.FC<ImageSectionProps> = ({
  lot,
  setVimeoDeleteAction,
  setVimeoVideo,
}) => {
  const toast = useToast();
  const {
    images,
    showImages,
    setShowImages,
    handleDragEnd,
    handleDeleteImage,
    imagePositions,
    setImagePositions,
    reorderImages,
    setReorderImages,
    saveImageOrdering,
    handleDeleteVimeo,
    showNewModal,
    setShowNewModal,
    uploadImages,
    newVimeoForImageAt,
    setNewVimeoForImageAt,
    updateLotImageVimeo,
    uploadThumbnailImage,
    imageToAddThumbnail,
    setImageToAddThumbnail,
  } = useImagesSection(lot.id, toast, setVimeoDeleteAction);
  const { updateLot } = useLot(lot.id);
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  });
  const keyboardSensor = useSensor(KeyboardSensor);
  const sensors = useSensors(mouseSensor, keyboardSensor);

  return (
    <>
      <VimeoFormModal
        visible={newVimeoForImageAt > -1 && !!images}
        onCancel={() => setNewVimeoForImageAt(-1)}
        onConfirm={updateLotImageVimeo}
      />
      <BulkUploadModal
        visible={showNewModal}
        setVisible={setShowNewModal}
        onConfirm={uploadImages}
      />
      <UploadThumbnailModal
        image={imageToAddThumbnail}
        visible={!!imageToAddThumbnail}
        setVisible={setImageToAddThumbnail}
        onConfirm={uploadThumbnailImage}
      />
      <h2>
        Image Gallery{" "}
        <span className="as_link" onClick={() => setShowImages(!showImages)}>
          {showImages ? "Hide" : "Show"}
        </span>
      </h2>
      {showImages && images ? (
        <div className="box">
          <InlineEdit
            label="Hide Whitespace Carousel"
            name="hideWhitespaceCarousel"
            initialValue={lot.hideWhitespaceCarousel || false}
            inputType="checkbox"
            onSubmit={updateLot}
          />
          <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
            <SortableContext
              items={images.map((image) => image.id)}
              strategy={rectSortingStrategy}
            >
              <div
                className="columns is-multiline"
                style={{ overflow: "auto" }}
              >
                {images.map((image, i) => (
                  <SortableImageCard
                    key={image.id}
                    index={i}
                    image={image}
                    onDelete={handleDeleteImage}
                    imagePositions={imagePositions}
                    setImagePositions={setImagePositions}
                    reorderImages={reorderImages}
                    handleDeleteVimeo={handleDeleteVimeo}
                    addThumbnail={setImageToAddThumbnail}
                    setVimeoVideo={setVimeoVideo}
                    setNewVimeoForImageAt={setNewVimeoForImageAt}
                  />
                ))}
              </div>
            </SortableContext>
          </DndContext>
          <div className="buttons is-right">
            {reorderImages ? (
              <>
                <button
                  className="button is-danger is-light"
                  onClick={() => setReorderImages(false)}
                >
                  Cancel
                </button>
                <button
                  className="button is-primary"
                  onClick={() => saveImageOrdering()}
                >
                  Save Ordering
                </button>
              </>
            ) : (
              <>
                <button
                  className="button"
                  onClick={() => setReorderImages(true)}
                >
                  Reorder
                </button>
                <button
                  className="button"
                  onClick={() => {
                    setShowNewModal(true);
                  }}
                >
                  Add
                </button>
              </>
            )}
          </div>
        </div>
      ) : null}
    </>
  );
};

const SortableImageCard: React.FC<{
  index: number;
  image: LotsImage;
  onDelete: (image: any) => void;
  imagePositions: any;
  setImagePositions: (imagePositions: { [key: string]: number }) => void;
  reorderImages: boolean;
  handleDeleteVimeo: (image: any) => void;
  addThumbnail: (image: any) => void;
  setVimeoVideo: (video: any) => void;
  setNewVimeoForImageAt: (index: number) => void;
}> = ({
  index,
  image,
  onDelete,
  imagePositions,
  setImagePositions,
  reorderImages,
  handleDeleteVimeo,
  addThumbnail,
  setVimeoVideo,
  setNewVimeoForImageAt,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: image.id,
      resizeObserverConfig: {
        disabled: false,
      },
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: "grab",
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="column is-one-fifth"
    >
      <div className="card">
        <div className="card-image">
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img alt="" src={image.fullSizeImageUrl} className="img-draggable" />
        </div>
        <div className="card-content">
          <div>
            <span
              title={image.fullSizeImage?.filename}
              style={{
                fontSize: "11px",
                wordBreak: "break-word",
              }}
            >
              {image.fullSizeImage?.filename}
            </span>
          </div>
          <div style={{ fontSize: "12px", marginTop: "5px" }}>
            Position:{" "}
            {reorderImages ? (
              <input
                type="text"
                value={imagePositions[image.id]}
                onChange={(e) => {
                  setImagePositions({
                    ...imagePositions,
                    [image.id]: e.target.value,
                  });
                }}
              />
            ) : (
              <span>{image.position}</span>
            )}
          </div>
          <div style={{ fontSize: "12px" }}>
            Have thumbnail?: {image.thumbnailImageUrl ? "Yes" : "No"}
          </div>
          <div style={{ paddingBottom: "5px" }}>
            {videoTag(image.vimeoVideo?.id)}
          </div>
          <div className="buttons">
            <button
              className="button is-primary is-light"
              onClick={() => {
                addThumbnail(image);
              }}
            >
              {image.thumbnailImageUrl ? "Change" : "Add"} thumbnail
            </button>
            {image.vimeoVideo ? (
              <button
                className="button is-primary is-light"
                onClick={() => {
                  image.vimeoVideo
                    ? setVimeoVideo(image.vimeoVideo)
                    : setNewVimeoForImageAt(index);
                }}
              >
                Play Vimeo
              </button>
            ) : null}
            <button
              className="button is-warning is-light"
              onClick={() => {
                setNewVimeoForImageAt(index);
              }}
            >
              {image.vimeoVideo ? "Change Vimeo" : "Add Vimeo"}
            </button>
            {image.vimeoVideo ? (
              <button
                className="button is-secondary is-danger is-light"
                onClick={() => {
                  handleDeleteVimeo(image);
                }}
              >
                Delete Vimeo
              </button>
            ) : null}
            <button
              className="button is-danger is-light"
              onClick={() => onDelete(image)}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageSection;
