import React, { useState } from "react";
import useFetching from "../../utils/useFetching";
import { LotsPast } from "../../generated/types";
import { put } from "../../utils/http";
import { useToast } from "../../providers/notification.provider";
import * as backend from "../../Backend";
import { formatDateToUtc } from "../../Util";

const usePastLots = () => {
  const [page, setPage] = useState(1);
  const toast = useToast();
  const [reorderPastLots, setReorderPastLots] = useState(false);
  const [pastLotThumbnailToUpdate, setPastLotThumbnailToUpdate] =
    useState<LotsPast | null>(null);
  const [pastLotDetailImageToUpdate, setPastLotDetailImageToUpdate] =
    useState<LotsPast | null>(null);
  const [pastLotVideoToUpdate, setPastLotVideoToUpdate] =
    useState<LotsPast | null>(null);
  const [pastLotToUpdate, setPastLotToUpdate] = useState<LotsPast | null>(null);
  const [pastLotToDelete, setPastLotToDelete] = useState<LotsPast | null>(null);

  const {
    data: pastLots,
    setData: setPastLots,
    meta,
    isLoading,
    onLoadData,
  } = useFetching<LotsPast[]>("/lots/pasts", { page }, [page]);

  const onChange = (page: number) => setPage(page);

  const updatePastLotsPositions = async () => {
    const positionsUpdated = (pastLots as any).reduce(
      (acc: any, pastLot: any) => {
        acc[pastLot.id] = pastLot.position;
        return acc;
      },
      {} as any,
    );

    await put("/lots/pasts/positions", { data: positionsUpdated });
    await onLoadData();
    setReorderPastLots(false);
    toast({ message: "Positions updated", type: "good" });
  };

  const updatePastLot = (
    pastLot: LotsPast,
    soldOn?: string,
    soldPrice?: string,
    published?: boolean,
  ) => {
    backend.pastLot
      .update(pastLot.id, {
        ...(soldOn && { soldOn }),
        ...(soldPrice && { soldPrice }),
        ...(published !== undefined && { published }),
      })
      .then(() => {
        setPastLotToUpdate(null);
        return onLoadData();
      });
  };

  const deletePastLot = (pastLot: LotsPast) => {
    backend.pastLot.delete(pastLot.id).then(() => {
      setPastLotToDelete(null);
      onLoadData();
    });
  };

  const onCancelThumbnail = () => {
    pastLotThumbnailToUpdate !== null
      ? setPastLotThumbnailToUpdate(null)
      : setPastLotDetailImageToUpdate(null);
  };

  const updatePastLotThumbnail = async (
    pastLot: LotsPast,
    file: any,
    width: number,
    height: number,
  ) => {
    await backend.pastLot.updateThumbnail(pastLot.id, file.item(0), {
      width,
      height,
    });
    await onLoadData();
    setPastLotThumbnailToUpdate(null);
  };

  const updatePastLotDetailImage = async (
    pastLot: LotsPast,
    file: any,
    width: number,
    height: number,
  ) => {
    await backend.pastLot.updateDetailImage(pastLot.id, file.item(0), {
      width,
      height,
    });
    await onLoadData();
    setPastLotDetailImageToUpdate(null);
  };

  const onConfirmThumbnail = (file: any, width: any, height: any) => {
    if (pastLotThumbnailToUpdate) {
      updatePastLotThumbnail(pastLotThumbnailToUpdate!, file, +width, +height);
      return;
    }

    updatePastLotDetailImage(
      pastLotDetailImageToUpdate!,
      file,
      +width,
      +height,
    );
  };

  const updatePastLotVideo = async (
    pastLot: LotsPast,
    vimeoId: string,
    width: number,
    height: number,
  ) => {
    await backend.pastLot.updateVideo(pastLot.id, { vimeoId, width, height });
    await onLoadData();
    setPastLotVideoToUpdate(null);
  };

  const columns = [
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
      render: (position: any, pastLot: LotsPast) =>
        reorderPastLots ? (
          <input
            id={`pastlot-${pastLot.id}`}
            className="input"
            type="text"
            placeholder={`${pastLot.position}`}
            value={position}
            onChange={(e) => {
              const newPosition = +e.target.value;
              setPastLots((pastLots: any) => {
                return pastLots.map((p: any) =>
                  p.id === pastLot.id ? { ...p, position: newPosition } : p,
                );
              });
            }}
          />
        ) : (
          pastLot.position
        ),
    },
    {
      title: "Thumbnail",
      dataIndex: "thumbnailImageUrl",
      key: "thumbnailImageUrl",
      render: (url: any, pastLot: LotsPast) =>
        url ? (
          // eslint-disable-next-line @next/next/no-img-element
          <img
            onClick={() => setPastLotThumbnailToUpdate(pastLot)}
            src={
              pastLot.thumbnailImage?.variants?.thumb?.url ||
              pastLot.thumbnailImageUrl
            }
            alt="thumbnail"
            style={{ width: "70px" }}
          />
        ) : (
          <button
            className="is-light is-small"
            onClick={() => setPastLotThumbnailToUpdate(pastLot)}
          >
            +
          </button>
        ),
    },
    {
      title: "Detail Image",
      dataIndex: "detailImageUrl",
      key: "detailImageUrl",
      render: (url: any, pastLot: LotsPast) =>
        url ? (
          // eslint-disable-next-line @next/next/no-img-element
          <img
            onClick={() => setPastLotDetailImageToUpdate(pastLot)}
            src={
              pastLot.detailImage?.variants?.thumb?.url ||
              pastLot.detailImageUrl
            }
            alt="detail"
            style={{ width: "70px" }}
          />
        ) : (
          <button
            className="is-light is-small"
            onClick={() => setPastLotDetailImageToUpdate(pastLot)}
          >
            +
          </button>
        ),
    },
    {
      title: "Video",
      dataIndex: ["vimeoVideo", "id"],
      key: "vimeoVideo.id",
      render: (id: any, pastLot: LotsPast) =>
        id ? (
          <button
            className="is-info"
            onClick={() => setPastLotVideoToUpdate(pastLot)}
          >
            #{id}
          </button>
        ) : (
          <button
            className="is-light is-small"
            onClick={() => setPastLotVideoToUpdate(pastLot)}
          >
            +
          </button>
        ),
    },
    { title: "Title", dataIndex: ["lot", "title"], key: "lot.title" },
    {
      title: "Artist",
      dataIndex: ["lot", "artistName"],
      key: "lot.artistName",
    },
    { title: "Estimate", dataIndex: ["lot", "estimate"], key: "lot.estimate" },
    {
      title: "Sold on",
      dataIndex: "soldOn",
      key: "soldOn",
      render: formatDateToUtc,
    },
    { title: "Sold price", dataIndex: "soldPrice", key: "soldPrice" },
    {
      title: "Published?",
      dataIndex: "published",
      key: "published",
      fixed: "right",
      render: (published: boolean, pastLot: LotsPast) => (
        <>
          <input
            id={`published-${pastLot.id}`}
            type="checkbox"
            name={`published-${pastLot.id}`}
            className="switch"
            checked={published}
            onChange={(e) => {
              updatePastLot(pastLot, undefined, undefined, e.target.checked);
            }}
          />
          <label htmlFor={`published-${pastLot.id}`}>
            {/* NOTE: Label used by bulma-switch to properly render the switch object */}
          </label>
        </>
      ),
    },
    {
      key: "actions",
      title: "",
      dataIndex: "actions",
      fixed: "right",
      render: (_: any, pastLot: LotsPast) => (
        <>
          <button
            className="button is-small is-primary"
            onClick={() => setPastLotToUpdate(pastLot)}
          >
            Edit
          </button>
          &nbsp;
          <button
            className="button is-small is-danger"
            onClick={() => deletePastLot(pastLot)}
          >
            Delete
          </button>
        </>
      ),
    },
  ];

  const onDisableReorderPastLots = () => setReorderPastLots(false);
  const onEnableReorderPastLots = () => setReorderPastLots(true);

  return {
    pastLots,
    meta,
    isLoading,
    onChange,
    columns,
    reorderPastLots,
    setReorderPastLots,
    updatePastLotsPositions,
    pastLotThumbnailToUpdate,
    pastLotDetailImageToUpdate,
    onCancelThumbnail,
    onConfirmThumbnail,
    pastLotVideoToUpdate,
    setPastLotVideoToUpdate,
    updatePastLotVideo,
    pastLotToDelete,
    deletePastLot,
    setPastLotToDelete,
    pastLotToUpdate,
    setPastLotToUpdate,
    updatePastLot,
    onDisableReorderPastLots,
    onEnableReorderPastLots,
  };
};

export default usePastLots;
