import Table from "../../Components/layouts/table";
import React from "react";
import FormModal from "../../Components/FormModal";
import ConfirmationModal from "../../Components/ConfirmationModal";
import { LotsPast, MetaResult } from "../../generated/types";

type PastLotsTableProps = {
  pastLots: LotsPast[] | null;
  meta: MetaResult | null;
  isLoading: boolean;
  columns: any[];
  onChange: (page: number) => void;
  reorderPastLots: boolean;
  setReorderPastLots: (value: boolean) => void;
  updatePastLotsPositions: () => void;
  pastLotThumbnailToUpdate: LotsPast | null;
  pastLotDetailImageToUpdate: LotsPast | null;
  onCancelThumbnail: () => void;
  onConfirmThumbnail: any;
  pastLotVideoToUpdate: LotsPast | null;
  setPastLotVideoToUpdate: (value: LotsPast | null) => void;
  updatePastLotVideo: (
    pastLot: LotsPast,
    videoId: string,
    width: number,
    height: number,
  ) => void;
  pastLotToDelete: LotsPast | null;
  deletePastLot: (pastLot: LotsPast) => void;
  setPastLotToDelete: (value: LotsPast | null) => void;
  pastLotToUpdate: LotsPast | null;
  setPastLotToUpdate: (value: LotsPast | null) => void;
  updatePastLot: (pastLot: LotsPast, soldOn: string, soldPrice: string) => void;
  onDisableReorderPastLots: () => void;
  onEnableReorderPastLots: () => void;
};

const PastLotsTable = ({
  pastLots,
  meta,
  isLoading,
  columns,
  onChange,
  pastLotThumbnailToUpdate,
  pastLotDetailImageToUpdate,
  onCancelThumbnail,
  onConfirmThumbnail,
  pastLotVideoToUpdate,
  setPastLotVideoToUpdate,
  updatePastLotVideo,
  pastLotToDelete,
  deletePastLot,
  setPastLotToDelete,
  pastLotToUpdate,
  setPastLotToUpdate,
  updatePastLot,
}: PastLotsTableProps) => {
  return (
    <>
      <ConfirmationModal
        bodyText={
          "Are you sure you want to remove this lot from the list of Past Lots?"
        }
        confirmText="Yes"
        cancelText="No"
        visible={pastLotToDelete !== null}
        onConfirm={() => deletePastLot(pastLotToDelete!)}
        onCancel={() => setPastLotToDelete(null)}
      />
      <FormModal
        form={[
          {
            label: "Enter sold price",
            inputPlaceholder:
              (pastLotToUpdate && pastLotToUpdate.soldPrice) || undefined,
          },
          { label: "Enter date at when the lot was sold", inputType: "date" },
        ]}
        confirmText="Update"
        visible={pastLotToUpdate !== null}
        onCancel={() => setPastLotToUpdate(null)}
        onConfirm={(soldPrice, soldOn) =>
          updatePastLot(pastLotToUpdate!, soldOn, soldPrice)
        }
      />
      <FormModal
        form={[
          {
            label:
              pastLotThumbnailToUpdate !== null ? "Thumbnail" : "Detail Image",
            inputType: "file",
            required: true,
          },
          { label: "Width", inputType: "number", required: false },
          { label: "Height", inputType: "number", required: false },
        ]}
        visible={
          pastLotThumbnailToUpdate !== null ||
          pastLotDetailImageToUpdate !== null
        }
        confirmText="Save"
        onCancel={onCancelThumbnail}
        onConfirm={onConfirmThumbnail}
      />

      <FormModal
        form={[
          { label: "Vimeo ID", inputType: "text", required: true },
          { label: "Width", inputType: "number", required: false },
          { label: "Height", inputType: "number", required: false },
        ]}
        visible={pastLotVideoToUpdate !== null}
        confirmText="Save"
        onCancel={() => setPastLotVideoToUpdate(null)}
        onConfirm={(videoId, width, height) =>
          updatePastLotVideo(pastLotVideoToUpdate!, videoId, +width, +height)
        }
      />
      <Table
        dataSource={pastLots}
        columns={columns}
        rowKey="id"
        meta={meta}
        loading={isLoading}
        onChange={onChange}
      />
    </>
  );
};

export default PastLotsTable;
