import React from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { UserProvider } from "./providers/user_provider";
import {
  AuthenticatedProvider,
  UnAuthenticatedProvider,
} from "./providers/authenticated_provider";
import AuctionRoomsNextPage from "./app/auction-rooms/page";
import AuctionRoomAlertsNextPage from "./app/auction-room-alerts/page";
import EventsNextPage from "./app/events/page";
import FeaturesNextPage from "./app/features/page";
import LotsNextPage from "./app/lots/page";
import MassNotificationsNextPage from "./app/mass-notifications/page";
import ParkingLotsNextPage from "./app/parking-lots/page";
import InviteCodesNextPage from "./app/invite-codes/page";
import UsersNextPage from "./app/users/page";
import UserDetailsNextPage from "./app/users/[id]/page";
import VipNextPage from "./app/vip/page";
import { NotificationsProvider } from "./providers/notification.provider";
import LotDetailsNextPage from "./app/lots/[id]/page";
import HomeNextPage from "./app/page";
import Layout from "./app/layout";
import SignInNextPage from "./app/auth/sign-in/page";
import ReferralCodesNextPage from "./app/referral-codes/page";
import PastLotsNextPage from "./app/past-lots/page";
import InternetConnectionProvider from "./providers/internet_connection.provider";

export const App = () => {
  return (
    <UserProvider>
      <NotificationsProvider>
        <InternetConnectionProvider>
          <AuthenticatedProvider>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Layout />}>
                  <Route path="/" element={<HomeNextPage />} />
                  <Route
                    path="/auction-rooms"
                    element={<AuctionRoomsNextPage />}
                  />
                  <Route
                    path="auction-rooms-alerts"
                    element={<AuctionRoomAlertsNextPage />}
                  />
                  <Route path="/events" element={<EventsNextPage />} />
                  <Route path="/features" element={<FeaturesNextPage />} />
                  <Route path="/lots" element={<LotsNextPage />} />
                  <Route path="/lots/:id" element={<LotDetailsNextPage />} />
                  <Route
                    path="/mass-notifications"
                    element={<MassNotificationsNextPage />}
                  />
                  <Route path="/past-lots" element={<PastLotsNextPage />} />
                  <Route
                    path="/parking-lots"
                    element={<ParkingLotsNextPage />}
                  />
                  <Route
                    path="/invite-codes"
                    element={<InviteCodesNextPage />}
                  />
                  <Route
                    path="/referral-codes"
                    element={<ReferralCodesNextPage />}
                  />
                  <Route path="/users" element={<UsersNextPage />} />
                  <Route path="/users/:id" element={<UserDetailsNextPage />} />
                  <Route path="/vip" element={<VipNextPage />} />
                  <Route path="*" element={<HomeNextPage />} />
                </Route>
              </Routes>
            </BrowserRouter>
          </AuthenticatedProvider>
          <UnAuthenticatedProvider>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Layout />}>
                  <Route path="/" element={<HomeNextPage />} />
                  <Route path="/auth/sign-in" element={<SignInNextPage />} />
                  <Route path="*" element={<HomeNextPage />} />
                </Route>
              </Routes>
            </BrowserRouter>
          </UnAuthenticatedProvider>
        </InternetConnectionProvider>
      </NotificationsProvider>
    </UserProvider>
  );
};
