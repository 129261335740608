import React from "react";
import { useToast } from "../../providers/notification.provider";
import { Feature } from "../../generated/types";
import { put } from "../../utils/http";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Tag } from "antd";
import Table from "../layouts/table";
import useFetching from "../../utils/useFetching";

const FeaturesPage = () => {
  const {
    data: features,
    onLoadData: onLoadFeatures,
    isLoading,
  } = useFetching<Feature[]>("/features");
  const toast = useToast();

  const ActionCell = (_: any, record: Feature) => {
    const onChangeActive = async () => {
      const body = {
        feature: { enabled: !record.enabled },
      };
      await put(`/features/${record.id}`, body);
      toast({ type: "good", message: "Feature updated." });
      onLoadFeatures();
    };

    return (
      <Tag className="cursor-pointer" color="default" onClick={onChangeActive}>
        {record.enabled ? "Disable" : "Enable"}
      </Tag>
    );
  };

  const columns = [
    { title: "id", dataIndex: "id", key: "id" },
    { title: "Name", dataIndex: "name", key: "name" },
    {
      title: "Enabled",
      dataIndex: "enabled",
      key: "enabled",
      render: (enabled: boolean) =>
        enabled ? <CheckOutlined /> : <CloseOutlined />,
    },
    { title: "Actions", key: "actions", render: ActionCell },
  ];

  return (
    <Table
      dataSource={features}
      columns={columns}
      rowKey="id"
      loading={isLoading}
    />
  );
};

export default FeaturesPage;
