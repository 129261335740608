import React, { useEffect, useState } from "react";
import * as backend from "../../Backend";
import LotForm from "../../Components/LotForm";
import { Lot, LotsParking } from "../../generated/types";
import { useForm } from "react-hook-form";
import { useToast } from "../../providers/notification.provider";
import { Link, useNavigate } from "../../utils/navigationUtils";
import LotsTable from "../../app/lots/LotsTable";

const LotsPage = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [parkingLot, setParkingLot] = useState<LotsParking | null>(null);
  const [showNewLot, setShowNewLot] = useState(false);
  const [showNewParkingLot, setShowNewParkingLot] = useState(false);
  const [upcomingLots, setUpcomingLots] = useState<Lot[] | null>(null);

  const [showImportLotModal, setShowImportLotModal] = useState(false);
  const [importLotJson, setImportLotJson] = useState<File | null | undefined>(
    null,
  );
  const [savingJsonLot, setSavingJsonLot] = useState(false);

  const {
    register: registerParkingLot,
    handleSubmit: handleParkingLotSubmit,
    formState: { errors: parkingLotErrors },
    reset: resetParkingLot,
  } = useForm();

  useEffect(() => {
    backend.lot.upcoming().then(setUpcomingLots);

    backend.parkingLots
      .current()
      .then(setParkingLot)
      .catch(() => setParkingLot(null));
  }, []);

  const createParkingLot = (data: any) => {
    backend.lot
      .createParkingLot(data)
      .then(setParkingLot)
      .then(() => {
        resetParkingLot();
        setShowNewParkingLot(false);
      });
  };

  const onImportLot = () => {
    setShowImportLotModal(true);
  };

  const onSubmitLotJson = async () => {
    setSavingJsonLot(true);
    await backend.lot.import(importLotJson!);
    setSavingJsonLot(false);
    setImportLotJson(null);
    setShowImportLotModal(false);
    toast({
      message: "Lot import started",
      type: "good",
    });
  };

  if (showNewLot) {
    return (
      <LotForm
        onSuccess={(l) => navigate(`/lots/${l.id}`)}
        onCancel={() => setShowNewLot(false)}
      />
    );
  }

  return (
    <>
      {showImportLotModal && (
        <div className={`modal ${showImportLotModal ? "is-active" : ""}`}>
          <div
            className="modal-background"
            onClick={() => setShowImportLotModal(false)}
          />
          <div className="modal-content">
            <div className="box">
              <div>Upload JSON File</div>
              <div>
                <input
                  type="file"
                  onChange={(e) => setImportLotJson(e.target.files?.[0])}
                />
              </div>
              {savingJsonLot ? (
                <div style={{ marginTop: "20px" }}>
                  <progress className="progress is-small is-primary" max="100">
                    40%
                  </progress>
                </div>
              ) : (
                <div className="buttons is-right">
                  <button
                    className="button is-primary"
                    onClick={onSubmitLotJson}
                  >
                    Save
                  </button>
                </div>
              )}
            </div>
          </div>
          <button
            className="modal-close is-large"
            onClick={() => setShowImportLotModal(false)}
          />
        </div>
      )}

      <div className={`modal ${showNewParkingLot ? "is-active" : ""}`}>
        <div
          className="modal-background"
          onClick={() => {
            setShowNewParkingLot(false);
            resetParkingLot();
          }}
        />
        <div className="modal-content">
          <div className="box">
            <form onSubmit={handleParkingLotSubmit(createParkingLot)}>
              <div className="field">
                <label htmlFor="taggedBody" className="label">
                  Body
                </label>
                <div className="control">
                  <textarea
                    className="input"
                    {...registerParkingLot("taggedBody", { required: true })}
                  />
                  {parkingLotErrors.taggedBody && (
                    <p className="help is-danger">Body required</p>
                  )}
                </div>
              </div>

              <div className="buttons is-right">
                <button className="button is-success" type="submit">
                  Create
                </button>
              </div>
            </form>
          </div>
        </div>
        <button
          className="modal-close is-large"
          onClick={() => {
            setShowNewParkingLot(false);
            resetParkingLot();
          }}
        />
      </div>

      <div className="columns is-vcentered">
        <div className="column">
          <nav className="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li className="is-active">
                <span className="as_link as_link_padding">Lots</span>
              </li>
            </ul>
          </nav>
        </div>
        <div className="column">
          <div className="buttons is-right">
            <button
              className="button is-primary"
              onClick={() => setShowNewLot(true)}
            >
              New Lot
            </button>
            <button className="button is-primary" onClick={onImportLot}>
              Import Lot
            </button>
          </div>
        </div>
      </div>

      {parkingLot ? (
        <div className="box" style={{ background: "#00d1b2" }}>
          <h2
            className="subtitle"
            style={{ color: "white", fontWeight: "bold" }}
          >
            Parking Lot Active
          </h2>
        </div>
      ) : null}

      <div className="box">
        <h2 className="subtitle">Upcoming Lots</h2>
        {upcomingLots ? (
          <div className="columns">
            <div className="column">
              <strong>Current</strong>
            </div>
            <div className="column">
              {upcomingLots[0] ? (
                <Link to={`/lots/${upcomingLots[0].id}`}>
                  {upcomingLots[0].title}
                </Link>
              ) : (
                "None"
              )}
            </div>
            <div className="column">
              <strong>Upcoming</strong>
            </div>
            <div className="column">
              {upcomingLots[1] ? (
                <Link to={`/lots/${upcomingLots[1].id}`}>
                  {upcomingLots[1].title}
                </Link>
              ) : (
                "None"
              )}
            </div>
          </div>
        ) : (
          <div>
            <progress className="progress is-small is-primary" max="100">
              15%
            </progress>
          </div>
        )}
      </div>

      <LotsTable />
    </>
  );
};

export default LotsPage;
