import { Lot } from "../../generated/types";
import { Link } from "../../utils/navigationUtils";
import BooleanOutlinedCell from "../../Components/layouts/cells/BooleanOutlinedCell";

const ActionCell = (_: any, record: Lot) => (
  <Link to={`/lots/${record.id}`} className="button is-small is-primary">
    View
  </Link>
);

export const columns = [
  { title: "Title", dataIndex: "title", key: "title" },
  { title: "Artist", dataIndex: "artistName", key: "artistName" },
  {
    title: "Auction Mobility ID",
    dataIndex: "auctionMobilityId",
    key: "auctionMobilityId",
  },
  {
    title: "Auction Starts At",
    dataIndex: "auctionStartsAt",
    key: "auctionStartsAt",
  },
  { title: "Slug", dataIndex: "slug", key: "slug" },
  { title: "Created At", dataIndex: "createdAt", key: "createdAt" },
  {
    title: "Active?",
    dataIndex: "active",
    key: "active",
    render: BooleanOutlinedCell,
  },
  {
    title: "Mixpanel?",
    dataIndex: ["configuration", "mixpanable"],
    key: "mixpanel",
    render: BooleanOutlinedCell,
  },
  {
    title: "Auction Live?",
    dataIndex: "auctionLive",
    key: "auctionLive",
    render: BooleanOutlinedCell,
  },
  {
    title: "",
    key: "actions",
    dataIndex: "actions",
    fixed: "right",
    render: ActionCell,
  },
];
